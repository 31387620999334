import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AddUpdateFoodInterface} from "../../../global-components/calendar-structure/administer/container/calendar-sidebar/food-view/add-update-food-dialog/add-update-food-interface";
import {ImageCropDialogComponent} from "../../../global-components/global-components/image-crop-dialog/image-crop-dialog.component";
import {SaveEventImageRequest} from "../../../models/api/requests/saveEventImage/saveEventImageRequest";
import {NetworkService} from "../../../services/network.service";
import {UpdateUserRequestInterface} from "../../../models/api/requests/updateUser/updateUserRequestInterface";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DateUtilsService} from "../../../services/date-utils.service";

@Component({
  selector: 'profile-completion-dialog',
  templateUrl: './profile-completion-dialog.component.html',
  styleUrls: ['./profile-completion-dialog.component.scss']
})
export class ProfileCompletionDialogComponent implements OnInit {
  editUserForm: FormGroup;
  newProfileImageUploaded= false;
  newBirthdateUploaded= false;
  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<ProfileCompletionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogInitData: {image: boolean, birthdate: boolean, userId: string},
              private dialog: MatDialog, private networkService: NetworkService,
              private snackBar: MatSnackBar,
              private dateUtils: DateUtilsService) { }

  ngOnInit(): void {
    this.editUserForm = this.fb.group({

      birthDate: ['', Validators.required ],
    });
  }

  openCropDialog(){
    const cropDialog = this.dialog.open(ImageCropDialogComponent);
    let url = '/saveMyImage';
    cropDialog.afterClosed().subscribe((base64EncdodedImage: string)=>{
      if(base64EncdodedImage != undefined && base64EncdodedImage != ""){
        this.networkService.executeEndpointRequest(url, {img: base64EncdodedImage, }).then((resp)=>{
          this.newProfileImageUploaded = true;
          this.snackBar.open("Profilbild erfolgreich geändert.", "", {
            duration:3000,
          });
          if((this.dialogInitData.birthdate && this.newBirthdateUploaded) || !this.dialogInitData.birthdate){
            this.dialogRef.close();
          }
          //this.snackBar.open("Bild erfolgreich geändert.", "", {duration: 3000});
          //this.getUserData(true);
        })
      }
    })
  }

    changed(birthDate: string) {
    const mysqlBirthDay = this.dateUtils.convertDateToMysqlString(new Date(this.editUserForm.get("birthDate").value));
      let user: UpdateUserRequestInterface = {id: this.dialogInitData.userId,  birthdate: mysqlBirthDay};

      this.networkService.updateUser(user).then(()=>{
        this.newBirthdateUploaded = true;
        this.snackBar.open("Daten erfolgreich geändert.", "", {
          duration:3000,
        });
      }).catch(()=>{
        this.snackBar.open("Es gab einen unerwarteten Fehler. Wende dich bitte an die Administratoren", "", {
          duration: 10000,
        });

      })
      if((this.dialogInitData.image && this.newProfileImageUploaded) || !this.dialogInitData.image){
        this.dialogRef.close();
      }

    }
}
