import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable()
export class NavigationService {
  sideBar: MatSidenav;
  constructor() { }

  public registerSideBar(sidaBar: MatSidenav){
    this.sideBar = sidaBar;
  }

  openSideBar(){
    this.sideBar.open()
  }

}