import { environment } from "src/environments/environment";


export class AtlasConsole{
    private _atlasVersion: string = "v3.0.1";

    public get atlas_version(): string {
        return this._atlasVersion;
    }

    constructor(){
        this._atlasVersion = environment.atlas_version;
    }
}