<h2>Bitte vervollständige dein Profil</h2>
<!--<file-input *ngIf="dialogInitData.image"></file-input>-->
<button mat-button *ngIf="dialogInitData.image && !newProfileImageUploaded" (click)="openCropDialog()">Profilbild Hochladen</button>
<br>

<div [formGroup]="editUserForm">
    <!--
<mat-form-field *ngIf="dialogInitData.birthdate && !newBirthdateUploaded">
    <mat-label>Geburtsdatum</mat-label>
    <input autocomplete="off" matInput formControlName="birthDate" type="date" (change)="changed('birthDate')">
</mat-form-field>-->

    <mat-form-field appearance="fill" *ngIf="dialogInitData.birthdate && !newBirthdateUploaded">
        <mat-label>Choose a date</mat-label>
        <input formControlName="birthDate" matInput [matDatepicker]="picker">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <br>
    <button mat-button (click)="changed('birthDate')" [disabled]="!editUserForm.get('birthDate').valid">Geburtsdatum speichern</button>
</div>
