import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  constructor(private navigationService: NavigationService) { }

  openSideBar(){
    this.navigationService.openSideBar();
  }
  openSideNav(){

  }
  ngOnInit() {
  }

}