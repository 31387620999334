
<mat-sidenav-container class="example-container">
  <mat-sidenav #sidenav mode="over" closed *ngIf="!hideNavigation">
    <side-bar></side-bar>
  </mat-sidenav>
  <mat-sidenav-content>

    <top-bar *ngIf="!hideNavigation"></top-bar>
    <div class="main_view">
      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>



