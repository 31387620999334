import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './image-crop-dialog.component.html',
  styleUrls: ['./image-crop-dialog.component.scss']
})
export class ImageCropDialogComponent implements OnInit {
  currentImage: string;

  constructor(private dialogRef: MatDialogRef<ImageCropDialogComponent>) { }

  eventImageChanged(event){
    this.currentImage = event;
  }

  save(){
    this.dialogRef.close(this.currentImage);
  }

  ngOnInit(): void {
  }

}
