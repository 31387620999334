import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { NetworkService } from '../../services/network.service';
import {environment} from "../../../environments/environment";
import {FileInputComponent} from "../../global-components/global-components/file-input/file-input.component";
import {MatDialog} from "@angular/material/dialog";
import {ProfileCompletionDialogComponent} from "./profile-completion-dialog/profile-completion-dialog.component";
const rootApiUrl:  string = environment.apiUrl//"https://atlas3-api.prometheusdb.at";

@Component({
  selector: 'side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  isLoggedIn: boolean = false;
  highestPermission: number = 0;
  profileImgPath: string = rootApiUrl+"/imgs/user_imgs/";
  checkProfileCompletionDone: boolean = false;

  constructor(private networkService: NetworkService, private router: Router,
              private dialog: MatDialog) { }

  logout() {
    this.networkService.logout();
  }

  private imageExists(image_url){

    var http = new XMLHttpRequest();

    http.open('HEAD', image_url, false);
    http.send();

    return http.status != 404;

  }



  private checkProfileCompletion(resp: any, imageExists: boolean){
    console.log("checking profile")
    if((resp.user.Geburtstag === null || resp.user.Geburtstag === "0000-00-00") && !imageExists){
      let dialog = this.dialog.open(ProfileCompletionDialogComponent, {data:{image: true, birthdate: true, userId: resp.user.id}});
      console.log("date of birth missing")
    }else if(resp.user.Geburtstag === null || resp.user.Geburtstag === "0000-00-00"){
      console.log("date of birth missing")
      let dialog = this.dialog.open(ProfileCompletionDialogComponent, {data:{image: false, birthdate: true, userId: resp.user.id}});

    }else if( !imageExists){
      let dialog = this.dialog.open(ProfileCompletionDialogComponent, {data:{image: true, birthdate: false, userId: resp.user.id}});


      console.log("date of birth missing")
    }
    this.checkProfileCompletionDone = true;
  }
  private checkLoginState() {
    this.networkService.checkLogin((resp) => {
      let isLoggedIn = resp.success;
      this.highestPermission = resp.highesPermission;
      const imageExists = this.imageExists(this.profileImgPath+resp.user.imgSrc)

      if(Object.keys(resp.user).length != 0 && !this.checkProfileCompletionDone){
        this.checkProfileCompletion(resp, imageExists);
      }
      //profileImgPath


      this.isLoggedIn = isLoggedIn;
    }, () => {

    })

  }

  ngOnInit() {
    this.checkLoginState()
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e) => {
      this.checkLoginState()
     })
  }

}
