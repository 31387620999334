import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NetworkService } from 'src/app/services/network.service';
import { OverviewEvent } from '../overview/overview-event';

@Component({
    selector: 'archive',
    templateUrl: './datenschutz.component.html'
})
export class DatenschutzComponent   {



    constructor(private networkService: NetworkService, private router: Router){

    }


}
