import {Injectable} from '@angular/core';
//import {Http, ResponseContentType} from '@angular/http';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileService {

  constructor(private http: HttpClient) {}


  downloadFile(url: string): Observable<HttpResponse<Blob>>{		
    //'https://atlas.prometheusdb.at/apl/ListGenerator?templateName=WholeEvent&eventId=81&oneSheetPerDay=true&oneSheetPerPosition=false'
		return this.http.get(url, {observe: 'response', responseType: 'blob', withCredentials: true});
   }
   
}