import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { NavigationService } from './../services/navigation.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  @ViewChild('sidenav') sidenav: MatSidenav;
  hideNavigation: boolean = false;

  constructor(private navigationService: NavigationService, private router: Router) {
    console.log(this.router.url)
    //this.hideNavigation = this.router.url === "/";
   }

  ngAfterViewInit() {
    this.navigationService.registerSideBar(this.sidenav);
  }

}
