import { Injectable, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ManagerJobInterface } from '../global-components/calendar-structure/administer/interfaces/manager-job-interface';

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {


  constructor() { }

  addTimeToDateObject(time: string, date: Date): Date{
    let momentDate = moment(date)
    let timeArray: string[] = time.split(":");
    let hour: number = parseInt(timeArray[0]);
    let minute: number = parseInt(timeArray[1]);

    momentDate.minute(minute)
    momentDate.hour(hour)

    return momentDate.toDate();
  }

  getJobOverlayMap(allMyJobs:  ManagerJobInterface[], otherJobsInView:  any[]): {}{
    let overlappingJobsInView = {};

    otherJobsInView.forEach((position)=>{
      position.jobs.forEach((otherJob: ManagerJobInterface) => {
        if(otherJob.id == null){
          let overlaps = false;
          for (let index = 0; index < allMyJobs.length && !overlaps; index++) {
            const myJob: ManagerJobInterface = allMyJobs[index];
            if(myJob.notAttended != "true"){
              overlaps = this.doesMyJobAndOtherJobOverlap(myJob, otherJob, overlaps);
              if(overlaps){
                let uJobId = otherJob.alle_positionen_id+""+otherJob.alle_schichten_id;
                otherJob.overlaps = true;
                overlappingJobsInView[uJobId] = myJob;
              }
            }           
          }
        }
      })
    })
   return overlappingJobsInView;
    
  }

  private doesMyJobAndOtherJobOverlap(myJob: ManagerJobInterface, otherJob: ManagerJobInterface, overlaps: boolean) {
    let myJobStart = this.convertApiDateToMoment(myJob.alle_schichten_start);
    let myJobEnd = this.convertApiDateToMoment(myJob.alle_schichten_ende);

    let otherJobStart = this.convertApiDateToMoment(otherJob.alle_schichten_start);
    let otherJobEnd = this.convertApiDateToMoment(otherJob.alle_schichten_ende);

    overlaps = (myJobStart <= otherJobEnd) && (myJobEnd >= otherJobStart);
    return overlaps;
  }

  getMysqlDateFromDateAndTime(startTime: string, startDate: Date): string {
    console.log(startDate)
    let startHours: number = startTime.split(":")["0"];
    let startMinutes: number = startTime.split(":")["1"];
    startDate.setHours(startHours, startMinutes);

    let mysqlDate: string = this.convertDateToMysqlString(startDate);
    return mysqlDate;
  }

  convertApiDateToDatePickerDate(apiDate: string): string{
    return moment(apiDate, 'YYYY-MM-DD HH:mm:SS').toISOString();
  }

  convertApiDateToMoment(apiDate: string): moment.Moment{
    return moment(apiDate, 'YYYY-MM-DD HH:mm:SS');
  }

  convertApiDateToTimePickerTime(apiDate: string): string{
    return moment(apiDate, 'YYYY-MM-DD HH:mm:SS').format("HH:mm");
  }

  convertApiDateToDisplayDate(apiDate: string, withYear: boolean = false): string{
    if(withYear){
      return moment(apiDate, 'YYYY-MM-DD HH:mm:SS').format("DD.MM.YYYY");
    }else{
      return moment(apiDate, 'YYYY-MM-DD HH:mm:SS').format("DD.MM.");
    }
    
  }

  isStringValidLocalDate(stringDate): boolean{
    let momentDate = moment(stringDate, "DD.MM.YYYY HH:mm:SS")
    return momentDate.isValid();
  }

  convertLocalDatetimeToApiDatetime(localDate: string): string{
    return moment(localDate, "DD.MM.YYYY HH:mm:SS").format('YYYY-MM-DD HH:mm:SS');
  }

  convertApiDateToDisplayDatetime(apiDate: string, withYear: boolean = false): string{
    if(withYear){
      return moment(apiDate, 'YYYY-MM-DD HH:mm:SS').format("DD.MM.YYYY HH:mm:SS");
    }else{
      return moment(apiDate, 'YYYY-MM-DD HH:mm:SS').format("DD.MM. HH:mm:SS");
    }
    
  }

  convertDateToMysqlString(theDate: Date): string{
    let mysqlString: string = moment(theDate).format('YYYY-MM-DD HH:mm:SS');
    return mysqlString;
  } 
}
