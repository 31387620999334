
<mat-nav-list>
  <mat-list-item>
    <a matLine [routerLink]="['/overview']">
      <mat-icon>home</mat-icon>
      Home
    </a>

  </mat-list-item>
  <mat-divider></mat-divider>

  <h3 mat-subheader *ngIf="isLoggedIn">Mein Account</h3>
  <mat-list-item  *ngIf="isLoggedIn">
    <a matLine [routerLink]="['/user', 'me']">
      <mat-icon>settings_applications</mat-icon>
      Einstellungen
    </a>
  </mat-list-item>
  <mat-list-item *ngIf="isLoggedIn">
    <a matLine href="/" (click)="logout()">
      <mat-icon>power_settings_new</mat-icon>
      Ausloggen
    </a>
  </mat-list-item>

  <h3 mat-subheader *ngIf="isLoggedIn && highestPermission>1">Administrierung</h3>
  <mat-list-item *ngIf="isLoggedIn && highestPermission>1">
     <a matLine [routerLink]="['/users']">
       <mat-icon>settings_applications</mat-icon>User
      </a>
  </mat-list-item>
  <mat-list-item *ngIf="isLoggedIn && highestPermission>1">
     <a matLine [routerLink]="['/templates']">
       <mat-icon>mail_outline</mat-icon>
       E-Mail Templates
      </a>
  </mat-list-item>
  <mat-list-item *ngIf="isLoggedIn && highestPermission>1">
     <a matLine [routerLink]="['/groups']">
       <mat-icon>people_outline</mat-icon>
       Gruppen
       </a>
  </mat-list-item>
  <mat-list-item *ngIf="isLoggedIn && highestPermission>1">
     <a matLine [routerLink]="['/archive']">  
       <mat-icon>folder_open</mat-icon>
     Archiv
     </a>
  </mat-list-item>
  <mat-list-item *ngIf="isLoggedIn && highestPermission>1">
    
    <a matLine href="...">
      <mat-icon>power_settings_new</mat-icon>
      Ankündigungen
    </a>

        
  </mat-list-item>
 <!-- <mat-list-item>
    <a matLine href="...">
      <mat-icon>power_settings_new</mat-icon>
      Eventmanagement
    </a>
  </mat-list-item>-->

  <h3 mat-subheader *ngIf="isLoggedIn && highestPermission>1">System</h3>
  <mat-list-item *ngIf="isLoggedIn && highestPermission>1">
     <a matLine href="...">
        <mat-icon>home</mat-icon>
        Info
        </a>
  </mat-list-item>
  <mat-divider></mat-divider>
</mat-nav-list>