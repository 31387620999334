import { Component, OnInit } from '@angular/core';

import {Router} from "@angular/router"
import { interval } from 'rxjs';
import { ConnectionService } from './services/connection.service';
import { environment } from './../environments/environment';
import { first } from 'rxjs/operators';
import { HttpResponse, HttpResponseBase } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.css' ]
})
export class AppComponent  {
  name = 'Angular';
  isLoggedIn: boolean = false;
  
  private source = interval(
   120 * 1000
 );
  

  constructor(private router: Router, private connectionService: ConnectionService, private _snackBar: MatSnackBar) { }

  private handleConnectionLoss(){
    //@TODO Tell networkservice that connection is lost
    this._snackBar.open("You are not connected to the Server. Please check your Wifi.", "", {
      duration: 5 * 1000,
    });

  }

  private checkConnection(){

    this.connectionService
    .checkIfApiIsAvailable()
    .pipe(first())
    .subscribe((resp: HttpResponseBase)=> {
      if (resp.status === 200 ) {
       console.log("Heartbeat")
      } else {
       this.handleConnectionLoss()
      }
    }, err => {
     if (err.status === 200 ) {
       console.log("Heartbeat")
     } else {
       this.handleConnectionLoss()
     }
    });
  }

  ngOnInit() {
    this.checkConnection()

    this.source.subscribe(() => {
      this.checkConnection()
   });
 
    //this.login();
    //this.checkLogin()
    setTimeout(()=>{
    //this.checkLogin()
    }, 2000)
  }
}
