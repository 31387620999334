import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { NetworkService } from './services/network.service';
import { NavigationComponent } from './navigation/navigation.component';
import { TopBarComponent } from './navigation/top-bar/top-bar.component';
import {MatBadgeModule} from '@angular/material/badge';


import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import { NavigationService } from './services/navigation.service';
import { MultiSwitchComponent } from './global-components/multi-switch/multi-switch.component';
import { SideBarComponent } from './navigation/side-bar/side-bar.component';
import { FileService } from './services/file.service';




import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { GroupsComponent } from './pages/groups/groups.component';
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import { ProfileCompletionDialogComponent } from './navigation/side-bar/profile-completion-dialog/profile-completion-dialog.component';
import {GlobalComponentsModule} from "./global-components/global-components/global-components.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {DatenschutzComponent} from "./pages/datenschutz/datenschutz.component";
import {MatDatepickerModule} from "@angular/material/datepicker";

@NgModule({
    imports: [BrowserModule, FormsModule, HttpClientModule, RouterModule.forRoot([
        {path: '', loadChildren: () => import('./pages/index/index.module').then(m => m.IndexModule)},
        {path: 'overview', loadChildren: () => import('./pages/overview/overview.module').then(m => m.OverviewModule)},
        {path: 'datenschutz', component: DatenschutzComponent},
        {
            path: 'administer/:id',
            loadChildren: () => import('./pages/administer/administer.module').then(m => m.AdministerModule)
        },
        {path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule)},
        {path: 'user/:id', loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule)},
        {path: 'manager/:id', loadChildren: () => import('./pages/manager/manager.module').then(m => m.ManagerModule)},
        {path: 'groups/:eventId', loadChildren: () => import('./pages/groups/groups.module').then(m => m.GroupsModule)},
        {path: 'groups', loadChildren: () => import('./pages/groups/groups.module').then(m => m.GroupsModule)},
        {path: 'group/:id', loadChildren: () => import('./pages/group/group.module').then(m => m.GroupModule)},
        {
            path: 'templates',
            loadChildren: () => import('./pages/templates/templates.module').then(m => m.TemplatesModule)
        },
        {
            path: 'templates/:eventId',
            loadChildren: () => import('./pages/templates/templates.module').then(m => m.TemplatesModule)
        },
        {
            path: 'template/:id',
            loadChildren: () => import('./pages/template/template.module').then(m => m.TemplateModule)
        },
        {path: 'archive', loadChildren: () => import('./pages/archive/archive.module').then(m => m.ArchiveModule)},
        {
            path: 'passwordreset',
            loadChildren: () => import('./pages/passwordreset/passwordreset.module').then(m => m.PasswordresetModule)
        },
        {
            path: 'activateaccount',
            loadChildren: () => import('./pages/activateaccount/activateaccount.module').then(m => m.ActivateaccountModule)
        }
    ], {relativeLinkResolution: 'legacy'}),
        BrowserAnimationsModule,

        MatIconModule, MatToolbarModule, MatSidenavModule, MatSnackBarModule, FormsModule, MatListModule, MatButtonModule, MatBadgeModule,
        RecaptchaV3Module, MatSnackBarModule, MatDialogModule, GlobalComponentsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatDatepickerModule
    ],
  declarations: [ AppComponent, NavigationComponent, TopBarComponent, SideBarComponent, ProfileCompletionDialogComponent, DatenschutzComponent ],
  bootstrap:    [ AppComponent ],
  providers: [NetworkService, NavigationService, FileService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcKdrkUAAAAAKlA3hGgmbh-QleoTK2Baf2yWG56' }],
  exports: [FormsModule]
})
export class AppModule { }
