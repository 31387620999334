import { Component, OnInit, ChangeDetectionStrategy, Output, Injector } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';


/**
 * can be dialog or pure component.
 */
@Component({
  selector: 'file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileInputComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: string = ''; //base64 String
  @Output() imageChanged = new EventEmitter();
  dialogRef: MatDialogRef<FileInputComponent>;
  aspect = 4 / 3;

  constructor(private injector: Injector) { 
      try{
        this.dialogRef = this.injector.get(MatDialogRef);
        this.aspect = 4/4;
      }catch{

      }  
  }

  get isDialog(): boolean{
      return this.dialogRef != undefined;
  }


  save(){
    this.dialogRef.close(this.croppedImage)
  }

  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
      this.imageChanged.emit(this.croppedImage)
  }
  imageLoaded(image: HTMLImageElement) {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  ngOnInit(): void {
  }

}
