import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { EventVisibilityStates } from '../models/enums/eventVisibilityStates';
import { EventFoodStates } from '../models/enums/eventFoodStates';
import { UpdateEventRequestInterface } from '../models/api/requests/updateEvent/updateEventRequestInterface';
import { UpdateUserRequestInterface } from '../models/api/requests/updateUser/updateUserRequestInterface';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AddShiftRequestInterface } from '../models/api/requests/addWork/addShiftRequestInterface';
import { environment } from './../../environments/environment';
import { BaseAtlasRequest } from '../models/api/requests/BaseAtlasRequest';
import { AtlasResponseInterface } from '../models/api/requests/atlasResponseInterface';
import { BehaviorSubject } from 'rxjs';

//const rootApiUrl:  string = "https://atlas.prometheusdb.at";
const rootApiUrl:  string = environment.apiUrl//"https://atlas3-api.prometheusdb.at";
@Injectable()
export class NetworkService {
  private loggedInUserId: string;

  private highestPermission = new BehaviorSubject<number>(-2);
  //public onhighestPermissionChange = this.highestPermission.asObservable();

  constructor(private http: HttpClient,  private router: Router,private recaptchaV3Service: ReCaptchaV3Service) {
  }

  getHighestPermissionObserver(){
    return this.highestPermission.asObservable();
  }

  getLoggedInUserId(): string{
    return this.loggedInUserId;
  }

  /**
   * Checks if the current Browser-Session is logged in.
   * @param success
   * @param errorCallback
   */
  checkLogin(success: ({success: boolean, highesPermission: number, user: any})=>void, errorCallback: (error: any)=>void){
    let _self = this;
    this.http.get(rootApiUrl+"/reload/user/me", { observe: 'response',  withCredentials: true }).subscribe((resp: any) => {
      console.log("You are logged in!")
      if(success != undefined){
        //console.log(resp.body)
        this.loggedInUserId = resp.body.data.user.id;
        let highestPermission = resp.body.data.highestPermission;
        _self.highestPermission.next(highestPermission);
        success({success: true, highesPermission: highestPermission, user: resp.body.data.user})
      }
      //this.isLoggedIn = true;
    }, error => {
      this.loggedInUserId = undefined;
      if(error.error.text.indexOf("login-card") != -1){
        success({success: false, highesPermission: -1, user: {}})
      }else{
        errorCallback(error)
      }
    })
  }

  /**
   * Checks if the current Browser-Session is logged in.
   * @param success
   * @param errorCallback
   */
   permissionLevelRequired(requiredPermissionLevel: number ,success: ({hasPermission: boolean})=>void, errorCallback: (error: any)=>void){
    let _self = this;
    this.http.get(rootApiUrl+"/reload/user/me", { observe: 'response',  withCredentials: true }).subscribe((resp: any) => {

      if(success != undefined){
        let highestPermission = resp.body.data.highestPermission;
        if(highestPermission<requiredPermissionLevel){
          success({hasPermission: false})
        }else{
          console.log("Permission granted")
          success({hasPermission: true})
        }
        _self.highestPermission.next(highestPermission);
      }
      //this.isLoggedIn = true;
    }, error => {
      console.log(error)
      success({hasPermission: false})
    })
  }

  //MOCK
  public getPositionWorkflow(positionId: number) : Promise<any>{
    let promise = new Promise((resolve, reject)=>{

      let workflow = {checkedTicks: 0};
      if(positionId == 126){
        workflow.checkedTicks = 3;
      }

      if(positionId == 125){
        workflow.checkedTicks = 2;
      }
      resolve(workflow)
    })
    return promise;
  }

  //MOCK
  public getComments(positionId: number) : Promise<any>{
    let promise = new Promise((resolve, reject)=>{

      let comments = [];

      comments.push({id: "1", content: "abcde <span class=\"mention\" data-index=\"1\" data-denotation-char=\"#\" data-value=\"Product Management\">﻿<span contenteditable=\"false\"><span class=\"ql-mention-denotation-char\">#</span>Product Management</span>﻿</span>", creation_time: "alkj", creator_id: ""});
      comments.push({id: "2", content: "Hi ", creation_time: "alkj", creator_id: ""});
      comments.push({id: "3", content: "was ist das ?", creation_time: "alkj", creator_id: ""});
      comments.push({id: "4", content: "<span class=\"mention\">@Max</span>, passt das so ?", creation_time: "alkj", creator_id: ""});

      resolve(comments)
    })
    return promise;
  }

  public simpleApiRequest(target: string): Promise<AtlasResponseInterface<any>>{
    let promise = new Promise<AtlasResponseInterface<any>>((resolve, reject)=>{
      this.makeApiPostRequest("/reload/"+target ,{},(resp: HttpResponse<any>)=>{
        resolve(resp.body)
      }, error=>{
        reject(error)
      })
    })
    return promise;
  }

  public executeBaseAtlasPostRequest(request: BaseAtlasRequest) : Promise<AtlasResponseInterface<any>>{
    let promise = new Promise<AtlasResponseInterface<any>>((resolve, reject)=>{
      this.makeApiPostRequest("/"+request.operationName ,request.getBody(),(resp: HttpResponse<any>)=>{
        resolve(resp.body)
      }, error=>{
        reject(error)
      })
    })
    return promise;
  }

  public executeEndpointRequest(endpoint: string, data: {}) : Promise<AtlasResponseInterface<any>>{
    let promise = new Promise<AtlasResponseInterface<any>>((resolve, reject)=>{
      this.makeApiPostRequest(endpoint ,data ,(resp: HttpResponse<any>)=>{
        resolve(resp.body)
      }, error=>{
        reject(error)
      })
    })
    return promise;
  }

  public getUser(userId: string) : Promise<any>{
    let promise = new Promise((resolve, reject)=>{
      this.makeApiGetRequest("/user/"+userId, (resp: HttpResponse<Object>)=>{
        resolve(resp)
      }, error=>{
        reject(error)
      })
    })
    return promise;
  }

  public getUsers() : Promise<any>{
    let promise = new Promise((resolve, reject)=>{
      this.makeApiGetRequest("/users", (resp: HttpResponse<Object>)=>{
        resolve(resp)
      }, error=>{
        reject(error)
      })
    })
    return promise;
  }

  public getEventDetails(eventId: number) : Promise<any>{
    let promise = new Promise((resolve, reject)=>{
      this.makeApiGetRequest("/getEventDetails/"+eventId, (resp: HttpResponse<Object>)=>{
        resolve(resp)
      }, error=>{
        reject(error)
      })
    })
    return promise;
  }

  public addShiftToEvent(addShiftRequest: AddShiftRequestInterface): Promise<any>{
    console.log(addShiftRequest)
    let promise = new Promise((resolve, reject)=>{
      this.makeApiPostRequest("/addwork", addShiftRequest, (resp: HttpResponse<Object>)=>{
        resolve(resp)
      }, error=>{
        reject(error)
      })
    })
    return promise;
  }

  public checkLoginAndRedirectIfFail(isLoggedInCallback: ()=>void){
    this.checkLogin((isLoggedIn)=>{
      if(isLoggedIn){
        isLoggedInCallback()
      }else{
        this.router.navigate(['/'])
      }
    }, ()=>{
      alert()
      this.router.navigate(['/'])
    })
  }


  private makeApiGetRequest(apiPath: string, success: (HttpResponse)=>void, errorCallback: (error: any)=>void){

        this.http.get(rootApiUrl+"/reload"+apiPath, { observe: 'response',  withCredentials: true }).subscribe(resp => {
          success(resp);
        }, error => {
          errorCallback(error);
        })
  }

  private makeApiPostRequest(apiPath: string, bodyObject: any, success: (HttpResponse)=>void, errorCallback: (error: any)=>void){
    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) =>{
        bodyObject["token"] = token;
        this.http.post(rootApiUrl+apiPath, bodyObject, { observe: 'response',  withCredentials: true }).subscribe(resp => {
          success(resp);
        }, error => {
          errorCallback(error);
        })
      });
  }

  getOverview(success: (HttpResponse)=>void, errorCallback: (error: any)=>void){
    this.makeApiGetRequest("/overview", (resp: HttpResponse<Object>)=>{
        success(resp.body)
    }, ()=>{
      errorCallback("Failed getting overview")
      console.error("Failed getting overview")
    })
  }

  getArchive(success: (HttpResponse)=>void, errorCallback: (error: any)=>void){
    this.makeApiGetRequest("/archive", (resp: HttpResponse<Object>)=>{
        success(resp)
    }, ()=>{
      errorCallback("Failed getting archive")
      console.error("Failed getting archive")
    })
  }


  /**
   *
   * @param userId
   * @param targetPositionId
   * @param targetShiftId
   * @param fields
   * @returns Promise
   */
  public moveUserFromToJob({ userId, originPosition, originShift, targetPositionId, targetShiftId, approvedState }: { userId: string; originPosition: string; originShift: string; targetPositionId: string; targetShiftId: string; approvedState: number }) : Promise<any>{
    let promise = new Promise((resolve, reject)=>{
      let requestUrl = "/moveWorkerBetweenJobs?userId="+userId
      requestUrl += "&originPosition="+originPosition+"&originShift="+originShift+"&targetPosition="+targetPositionId+"&targetShift="+targetShiftId+"&approvedState="+approvedState;
      this.makeApiGetRequest(requestUrl, (resp: HttpResponse<Object>)=>{
        resolve(resp)
      }, error=>{
        reject(error)
      })
    })
    return promise;
  }

  public updateUser(user: UpdateUserRequestInterface): Promise<any>{
    let promise = new Promise((resolve, reject)=>{
      let requestUrl = "/updateUser";

      this.makeApiPostRequest(requestUrl, user, (resp: HttpResponse<Object>)=>{
        resolve(resp)
      }, error=>{
        reject(error)
      })
    })
    return promise;
  }

  updateMyPassword(currentPassword: string, newPassword: string, email: string): Promise<any>{
    let promise = new Promise((resolve, reject)=>{
      let requestUrl = "/updatemypasswordoperation";
      let data = {current_password: currentPassword, new_password: newPassword, username: email};

      this.makeApiPostRequest(requestUrl, data, (resp: HttpResponse<Object>)=>{
        resolve(resp)
      }, error=>{
        reject(error)
      })
    })
    return promise;
  }


  updateEventVisibilityState(eventId: number, state: EventVisibilityStates): Promise<any>{
    let promise = new Promise((resolve, reject)=>{
      let requestUrl = "/updateevent?eventId="+eventId+"&eventState="+state;

      this.makeApiGetRequest(requestUrl, (resp: HttpResponse<Object>)=>{
        resolve(resp)
      }, error=>{
        reject(error)
      })
    })
    return promise;
  }

  updateEventFoodState(eventId: number, state: EventFoodStates): Promise<any>{
    let promise = new Promise((resolve, reject)=>{
      let requestUrl = "/updateevent?eventId="+eventId+"&hasfood="+state;

      this.makeApiGetRequest(requestUrl, (resp: HttpResponse<Object>)=>{
        resolve(resp)
      }, error=>{
        reject(error)
      })
    })
    return promise;
  }

  getAdminister(eventId: number) : Promise<any>{
    let promise = new Promise((resolve, reject)=>{
      this.makeApiGetRequest("/administerWithoutPermissions/"+eventId, (resp: HttpResponse<Object>)=>{
        resolve(resp)
      }, error=>{
        reject(error)
      })
    })
    return promise;
  }

  getManager(eventId: number) : Promise<any>{
    let promise = new Promise((resolve, reject)=>{
      this.makeApiGetRequest("/manager/"+eventId, (resp: HttpResponse<Object>)=>{
        resolve(resp)
      }, error=>{
        reject(error)
      })
    })
    return promise;
  }

  getProfile(){
    this.makeApiGetRequest("/users/me", (resp: HttpResponse<Object>)=>{
        console.log(resp)
    }, ()=>{
      console.error("Failed getting profile")
    })
  }

  logout(){

    this.http.get(rootApiUrl+"/logout", { observe: 'response',  withCredentials: true }).subscribe(resp => {
      //this.checkLogin(()=>{}, ()=>{})
    }, error => {
      //this.checkLogin(()=>{}, ()=>{})
    })
  }




  login(email: string, password: string, captchaObject: {type: string, token: string}, success: (HttpResponse)=>void, errorCallback: (error: any)=>void){
    let loginData = {"email":email,
      "password":password,
    "captchaData": captchaObject};

    this.http.post(rootApiUrl+"/login", loginData, { observe: 'response',  withCredentials: true }).subscribe(resp => {
      this.checkLogin(()=>{}, ()=>{})
      success(resp);

    }, error => {
      console.log(error.error)
      this.checkLogin(()=>{}, ()=>{})
      errorCallback(error)
    })
  }

}
