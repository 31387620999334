import { defaultConfiguration } from "./default";


export const environment = {
    production: true,
    apiUrl: 'https://atlas.prometheusdb.at/',
    assetsBaseUrl: 'https://atlas.prometheusdb.at/assets/',
    atlas_version: defaultConfiguration.atlas_version,
    features:{
      POSITION_COMMENT:{active: false},
      WORKFLOW: {active: false},
      POSITION_FILES: {active: false},
      POSITION_TODOS: {active: false},
    }
  };