import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SinglePasswordInputComponent } from './single-password-input/single-password-input.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { FileInputComponent } from './file-input/file-input.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatButtonModule } from '@angular/material/button';
import { UserSearchDialogComponent } from './user-search-dialog/user-search-dialog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { QuillModule } from 'ngx-quill';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { ChoiceDialogComponent } from './choice-dialog/choice-dialog.component';
import {MatRadioModule} from '@angular/material/radio';
import { ImageCropDialogComponent } from './image-crop-dialog/image-crop-dialog.component';
import { DateFromToDisplayComponent } from './date-from-to-display/date-from-to-display.component';
import { ApiDateToDisplayDatePipe } from './date-from-to-display/api-date-to-display-date.pipe';
import { MultiSwitchComponent } from '../multi-switch/multi-switch.component';
import { SimpleTableComponent } from './simple-table/simple-table.component';
import { TextfieldDialogComponent } from './textfield-dialog/textfield-dialog.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { EditModelDialogComponent } from './edit-model-dialog/edit-model-dialog.component';

import { DateTimeInputComponent } from './date-time-input/date-time-input.component';


/**
 * The Module containg shared components
 */
@NgModule({
  declarations: [MultiSwitchComponent, SinglePasswordInputComponent, FileInputComponent, UserSearchDialogComponent, ChoiceDialogComponent, ImageCropDialogComponent, DateFromToDisplayComponent, ApiDateToDisplayDatePipe, SimpleTableComponent, TextfieldDialogComponent, PhoneInputComponent, EditModelDialogComponent, DateTimeInputComponent],
  imports: [
    CommonModule,
    MatInputModule, MatButtonModule, MatTabsModule,
    MatTableModule, MatPaginatorModule, MatCheckboxModule,
    MatCardModule, MatPaginatorModule, MatIconModule, MatTooltipModule, MatDialogModule, FormsModule, MatButtonModule,
    MatSelectModule, MatSlideToggleModule, MatSliderModule, ReactiveFormsModule, QuillModule.forRoot(), MatInputModule, MatTabsModule,
    MatTableModule, MatBadgeModule, MatMenuModule, MatNativeDateModule, MatDatepickerModule, MatCheckboxModule, MatExpansionModule,
    MatRippleModule, MatAutocompleteModule, MatChipsModule, MatRadioModule, TextFieldModule, 

    FormsModule, MatPasswordStrengthModule, ImageCropperModule, NgxIntlTelInputModule
  ],
  exports: [SinglePasswordInputComponent, FileInputComponent, DateFromToDisplayComponent, MultiSwitchComponent, SimpleTableComponent, PhoneInputComponent, DateTimeInputComponent]
})
export class GlobalComponentsModule { }
