
<button type="button" mat-flat-button (click)="fileInput.click()" color="primary">
    <mat-icon>file_upload</mat-icon>
    Bild Auswählen
</button>

<input hidden #fileInput type="file" (change)="fileChangeEvent($event)" />
<div class="crop-container">
    <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="aspect"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
</div>

<button matRipple  mat-flat-button  *ngIf="isDialog" (click)="save()">Speichern</button>
<!--<img [src]="croppedImage" />-->