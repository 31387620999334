import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  constructor(private httpClient: HttpClient) { }

  checkIfApiIsAvailable() {
      var pingUrl = environment.apiUrl;
      return this.httpClient.get(pingUrl);
  }
}